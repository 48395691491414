<template>
  <teleport to="body">
    <div class="preview">
      <!-- Root element of PhotoSwipe. Must have class pswp. -->
      <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
        <!-- Background of PhotoSwipe. 
         It's a separate element as animating opacity is faster than rgba(). -->
        <div class="pswp__bg"></div>

        <!-- Slides wrapper with overflow:hidden. -->
        <div class="pswp__scroll-wrap">
          <!-- Container that holds slides. 
            PhotoSwipe keeps only 3 of them in the DOM to save memory.
            Don't modify these 3 pswp__item elements, data is added later on. -->
          <div class="pswp__container">
            <div class="pswp__item"></div>
            <div class="pswp__item"></div>
            <div class="pswp__item"></div>
          </div>

          <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
          <div class="pswp__ui pswp__ui--hidden">
            <div class="pswp__top-bar">
              <!--  Controls are self-explanatory. Order can be changed. -->

              <div class="pswp__counter"></div>

              <button
                class="pswp__button pswp__button--close"
                title="Close (Esc)"
              ></button>

              <!-- <button
                class="pswp__button pswp__button--share"
                title="Share"
              ></button> -->

              <button
                class="pswp__button pswp__button--fs"
                title="Toggle fullscreen"
              ></button>

              <button
                class="pswp__button pswp__button--zoom"
                title="Zoom in/out"
              ></button>

              <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
              <!-- element will get class pswp__preloader--active when preloader is running -->
              <div class="pswp__preloader">
                <div class="pswp__preloader__icn">
                  <div class="pswp__preloader__cut">
                    <div class="pswp__preloader__donut"></div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"
            >
              <div class="pswp__share-tooltip"></div>
            </div>

            <button
              class="pswp__button pswp__button--arrow--left"
              title="Previous (arrow left)"
            ></button>

            <button
              class="pswp__button pswp__button--arrow--right"
              title="Next (arrow right)"
            ></button>

            <div class="pswp__caption">
              <div class="pswp__caption__center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  PropType,
  nextTick,
  watchEffect,
  Events,
} from "vue";

import PhotoSwipe from "photoswipe";
import defaultUI from "photoswipe/dist/photoswipe-ui-default";
import { Options, Item } from "photoswipe/dist/photoswipe-ui-default";
export interface ImgItem extends Item {
  src: string;
  w?: number;
  h?: number;
}

export default defineComponent({
  name: "Preview",
  props: {
    imgList: {
      type: Array as PropType<ImgItem[]>,
      default: [],
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
  },
  emits: ["close"],
  component: {},
  setup(props, ctx) {
    let gallery: PhotoSwipe<Options> | null = null;

    function getSrcSize(src: string) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;

        let timer = 0;
        timer = setInterval(() => {
          if (img.width > 0) {
            clearInterval(timer);

            resolve({
              src: src,
              w: img.width,
              h: img.height,
            });
          }
        }, 40);
        img.addEventListener("error", (error) => {
          clearInterval(timer);
          resolve({
            src: src,
            w: 0,
            h: 0,
          });
        });
      });
    }
    function getSrcInfo(items: ImgItem[]) {
      return Promise.all(
        items.map((item) => {
          return getSrcSize(item.src);
        })
      );
    }

    async function init() {
      const pswpElement: HTMLElement | null = document.querySelector(".pswp");

      // build items array
      const arr = await getSrcInfo(props.imgList);

      const items = arr.filter((item) => (item as any).w > 0);
      if (!items.length) {
        ctx.emit("close");
        return false;
      }
      // define options (if needed)
      const options = {
        // optionName: 'option value'
        tapToClose: true,
        closeEl: false,
        // for example:
        index: props.currentIndex, // start at first slide
        fullscreenEl: false,
        closeOnVerticalDrag: true,
        // showAnimationDuration: 0,
        // hideAnimationDuration: 300,
        // shareButtons: [
        //     { id: 'wechat', label: '分享微信', url: '#' },
        //     { id: 'weibo', label: '新浪微博', url: '#' },
        //     { id: 'download', label: '保存图片', url: '{{raw_image_url}}', download: true }
        // ],
        // isClickableElement: function (el: HTMLElement) {
        //   console.log(el.tagName)
        //   if (gallery) {
        //     gallery.close();

        //     ctx.emit("close" , 0);
        //   }
        //   return true;
        // },
      };
      // Initializes and opens PhotoSwipe
      nextTick(() => {
        console.log(11111);

        gallery = new PhotoSwipe(
          pswpElement as HTMLElement,
          defaultUI,
          items as Item[],
          options
        );
        gallery.init();
        gallery.listen("close", function () {
          ctx.emit("close");
        });
      });
    }
    onMounted(() => {
      init();
    });
  },
});
</script>

<style lang="scss" scoped>
@import "~photoswipe/dist/photoswipe.css";
@import "~photoswipe/dist/default-skin/default-skin.css";

.preview {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1005;
}

.my-gallery {
  width: 100%;
  float: left;
}
.my-gallery img {
  width: 100%;
  height: auto;
}
.my-gallery figure {
  display: block;
  float: left;
  margin: 0 5px 5px 0;
  width: 150px;
}
.my-gallery figcaption {
  display: none;
}
</style>
